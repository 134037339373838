export enum PaymentTypes {
  CANCELED = '5dc56ff0-395e-465b-85b7-daa2bbb933a1',
  BANK_TRANSFER = 'a1d3e4c4-da3b-44af-8d31-5edaca1d02bc',
  BANK_TRANSFER_1000 = 'c1df3008-059a-4ce9-9732-380a979b88e9',
  BANK_TRANSFER_200 = '0686b066-86a1-4e08-a2c0-b992a9213d88',
  BANK_TRANSFER_2000 = '11bd70a8-19ab-4cb1-a7d0-7c51e471d07a',
  BANK_TRANSFER_3000 = 'f4568bed-516c-481d-8a58-c632490ca1f3',
  BANK_TRANSFER_500 = 'd052c920-002f-455c-a35e-99df9fafca5a',
  Y_BANK_TRANSFER = '7a4e369a-f8de-44b0-83a4-d425c762366f',
  FREE_NOT_PICKED_UP = '5171fe1b-e1cc-4fc9-8733-ab6cd52b833b',
  FREE_SPONZOR = 'e1164bfa-754a-4b85-bb3b-713235dc31eb',
  FREE_OBJECTION = 'a016bd35-44e5-4152-8f3a-f57c65868dd2',
  FREE_CANCEL = '5dc56ff0-395e-465b-85b7-daa2bbb933a1',
  FREE_DIFFERENT_ORDER = '1c9f0770-c47b-452d-9c25-448e76d4608d',
  CASH_ON_DELIVERY = '62602385-f1bb-4755-8a1d-69405197774f',
  PAYMENT_ON_SITE = '24efec4c-7f15-44e0-834c-5ac42e45c0b7',
  PRE_INVOICE_BANK_TRANSFER = 'b5ac7e18-048c-4e4d-8997-4da6e0d46a8c',
  PRE_INVOICE_CASH_ON_DELIVERY = 'f678ae9b-fd18-49e3-9346-98becaa4553a',
  PRE_INVOICE_PAYMENT_ON_SITE = '8159e746-fbed-48ff-b873-603d781019cc',
  PRE_INVOICE_PAYMENT_CASH = 'ce3345f1-2812-4641-a530-c94ded903408',
  CASH_PAYMENT_WITHOUT = '9f57c95e-2c11-4d35-b33c-3f213e9e8c9d',
}
