import { PaymentTypes } from '@asaprint/common/constants/PaymentType.js';

export function isBankPayment(paymentType: PaymentTypes): boolean {
  return [
    PaymentTypes.BANK_TRANSFER,
    PaymentTypes.BANK_TRANSFER_200,
    PaymentTypes.BANK_TRANSFER_500,
    PaymentTypes.BANK_TRANSFER_1000,
    PaymentTypes.BANK_TRANSFER_2000,
    PaymentTypes.BANK_TRANSFER_3000,
    PaymentTypes.Y_BANK_TRANSFER,
  ].includes(paymentType);
}

export function isPreInvoicePayment(paymentType: PaymentTypes): boolean {
  return [
    PaymentTypes.PRE_INVOICE_BANK_TRANSFER,
    PaymentTypes.PRE_INVOICE_CASH_ON_DELIVERY,
    PaymentTypes.PRE_INVOICE_PAYMENT_ON_SITE,
  ].includes(paymentType);
}

export function isInvoiceNeeded(paymentType: PaymentTypes): boolean {
  return (
    isBankPayment(paymentType) ||
    isPreInvoicePayment(paymentType) ||
    [PaymentTypes.CASH_ON_DELIVERY].includes(paymentType)
  );
}

export function isCardPayment(paymentType: PaymentTypes): boolean {
  return [PaymentTypes.PAYMENT_ON_SITE, PaymentTypes.PRE_INVOICE_PAYMENT_ON_SITE].includes(paymentType);
}

export function isCashPayment(paymentType: PaymentTypes): boolean {
  return [
    PaymentTypes.PAYMENT_ON_SITE,
    PaymentTypes.PRE_INVOICE_PAYMENT_ON_SITE,
    PaymentTypes.CASH_PAYMENT_WITHOUT,
  ].includes(paymentType);
}

export function isFreePayment(paymentType: PaymentTypes): boolean {
  return [
    PaymentTypes.FREE_SPONZOR,
    PaymentTypes.FREE_OBJECTION,
    PaymentTypes.FREE_CANCEL,
    PaymentTypes.FREE_DIFFERENT_ORDER,
    PaymentTypes.FREE_NOT_PICKED_UP,
  ].includes(paymentType);
}
