import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';

export function isCourierDelivery(deliveryType: DeliveryTypes): boolean {
  return [
    DeliveryTypes.COURIER_SPS,
    DeliveryTypes.COURIER_TNT,
    DeliveryTypes.COURIER_TOPTRANS,
    DeliveryTypes.COURIER_DPD_DS,
    DeliveryTypes.COURIER_GEIS_PARCEL,
    DeliveryTypes.COURIER_SLOVAK_POST,
    DeliveryTypes.ZASIELKOVNA,
  ].includes(deliveryType);
}

export function isAsaprintDelivery(deliveryType: DeliveryTypes): boolean {
  return [DeliveryTypes.ASAPRINT, DeliveryTypes.ASAPRINT_THURSDAY, DeliveryTypes.ASAPRINT_THURSDAY_FREE].includes(
    deliveryType,
  );
}

export function isPickupDelivery(deliveryType: DeliveryTypes): boolean {
  return [DeliveryTypes.PICKUP].includes(deliveryType);
}

export function isAssemblyDelivery(deliveryType: DeliveryTypes): boolean {
  return [DeliveryTypes.ASSEMBLY].includes(deliveryType);
}

export function isEmailDelivery(deliveryType: DeliveryTypes): boolean {
  return [DeliveryTypes.EMAIL].includes(deliveryType);
}
