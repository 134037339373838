import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { PaymentTypes } from '@asaprint/common/constants/PaymentType.js';
import {
  isAsaprintDelivery,
  isAssemblyDelivery,
  isCourierDelivery,
  isEmailDelivery,
  isPickupDelivery,
} from '@asaprint/common/helpers/DeliveryType.js';
import { isBankPayment, isFreePayment, isPreInvoicePayment } from '@asaprint/common/helpers/PaymentType.js';
import { addDays, formatISO } from 'date-fns';

interface Holiday {
  date: number;
  month: number;
}

const staticHolidays: Holiday[] = [
  { date: 1, month: 0 }, // 1.1
  { date: 6, month: 0 }, // 6.1
  { date: 1, month: 4 }, // 1.5
  { date: 8, month: 4 }, // 8.5
  { date: 5, month: 6 }, // 5.7
  { date: 29, month: 7 }, // 29.8
  { date: 1, month: 8 }, // 1.9
  { date: 15, month: 8 }, // 15.9
  { date: 1, month: 8 }, // 1.9
  { date: 1, month: 10 }, // 1.11
  { date: 17, month: 10 }, // 17.11
  { date: 24, month: 11 }, // 24.12
  { date: 25, month: 11 }, // 24.12
  { date: 26, month: 11 }, // 24.12
];

function easterHolidays(Y): Holiday[] {
  const C = Math.floor(Y / 100);
  const N = Y - 19 * Math.floor(Y / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
  let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);

  // Sunday
  return [-2, -1, 0, 1].map((d) => {
    const date = new Date(Y, M, D - d);
    return {
      date: date.getDate(),
      month: date.getMonth(),
    };
  });
}

export function isWorkDay(at: Date): boolean {
  const day = at.getDay();
  const month = at.getMonth();
  const date = at.getDate();

  // Sunday and Saturday
  if (day === 0 || day === 6) {
    return false;
  }

  // Static holidays
  for (const s of staticHolidays) {
    if (s.date === date && s.month === month) {
      return false;
    }
  }

  // Easter holiday
  for (const e of easterHolidays(at.getFullYear())) {
    if (e.date === date && e.month === month) {
      return false;
    }
  }

  return true;
}

/**
 * Calculate if order is from eshop
 */
export function isOrderReceivedFromEshop(orderReceived: { moneyNumber?: string }): boolean {
  return orderReceived.moneyNumber && orderReceived.moneyNumber[4] === '1';
}

type ScheduledFlags = {
  checkApprovedAt?: boolean;
  checkInspectionAt?: boolean;
  checkExpeditionAt?: boolean;
};

export function isScheduledToday(
  orderReceived: {
    expeditionAt?: string;
    assemblyAt?: string;
    approvedAt?: string;
    inspectionAt?: string;
  },
  flags: ScheduledFlags = {},
): boolean {
  return isScheduledAt(new Date(), orderReceived, flags);
}

/**
 * This is not real tomorrow -> but working day tomorrow
 */
export function isScheduledTomorrow(
  orderReceived: {
    expeditionAt?: string;
    assemblyAt?: string;
    approvedAt?: string;
    inspectionAt?: string;
  },
  flags: ScheduledFlags = {},
): boolean {
  let at = addDays(new Date(), 1);
  while (!isWorkDay(at)) {
    at = addDays(at, 1);
  }
  return isScheduledAt(at, orderReceived, flags);
}

export function isScheduledAt(
  at: Date,
  orderReceived: {
    expeditionAt?: string;
    assemblyAt?: string;
    approvedAt?: string;
    inspectionAt?: string;
  },
  { checkApprovedAt = true, checkInspectionAt = false, checkExpeditionAt = true }: ScheduledFlags = {},
): boolean {
  const atISO = formatISO(at, { representation: 'date' });
  return (
    (checkExpeditionAt &&
      orderReceived.expeditionAt &&
      formatISO(new Date(orderReceived.expeditionAt), { representation: 'date' }) === atISO) ||
    (orderReceived.assemblyAt && formatISO(new Date(orderReceived.assemblyAt), { representation: 'date' }) === atISO) ||
    (checkApprovedAt &&
      orderReceived.approvedAt &&
      formatISO(new Date(orderReceived.approvedAt), { representation: 'date' }) === atISO) ||
    (checkInspectionAt &&
      orderReceived.inspectionAt &&
      formatISO(new Date(orderReceived.inspectionAt), { representation: 'date' }) === atISO)
  );
}

export function isAssemblyFinished(orderReceived: { phase: string | OrderReceivedPhase }): boolean {
  return [
    OrderReceivedPhase.DONE,
    OrderReceivedPhase.CANCELED,
    OrderReceivedPhase.CHECK_2,
    OrderReceivedPhase.INVOICE,
  ].includes(orderReceived.phase as OrderReceivedPhase);
}

export function areCustomerEmailsIgnored(moneyNumber: string): boolean {
  return moneyNumber.slice(-5, -4) === '7' || moneyNumber.slice(-5, -4) === '8';
}

export function shouldSyncWithToggl(moneyNumber: string): boolean {
  return moneyNumber.slice(-5, -4) === '7';
}

export function getDisplayName(data: { moneyNumber?: string; moneyName?: string; moneyAddressName?: string }): string {
  return `${data.moneyNumber} ${data.moneyAddressName} - ${data.moneyName}`;
}

interface Data {
  id: string;
  moneyNumber?: string;
  moneyName?: string;
  moneyAddressName?: string;
}

export function toOption<TData extends Data>(data: TData) {
  return {
    label: getDisplayName(data),
    value: data.id,
    data,
  };
}

/**
 Bankovým prevodom
 + Doprava ASAPRINT
 + E-mailom
 + Kuriér
 + Montáž ASAPRINT
 + Osobný odber
 + Zásielkovňa

 Bez platby
 + Doprava ASAPRINT
 + E-mailom
 + Kuriér
 + Montáž ASAPRINT
 + Osobný odber
 + Zásielkovňa

 Hotovosť bez
 + Doprava ASAPRINT
 + E-mailom
 + Kuriér
 + Montáž ASAPRINT
 + Osobný odber
 + Zásielkovňa

 Zálohová platba
 + Doprava ASAPRINT
 + E-mailom
 + Kuriér
 + Montáž ASAPRINT
 + Osobný odber
 + Zásielkovňa

 Dobierkou
 + Kuriér
 + Zásielkovňa

 Platba pri odbere
 + E-mailom
 + Montáž ASAPRINT
 + Osobný odber
 */
export function isValidCombination(paymentType: PaymentTypes, deliveryType: DeliveryTypes): boolean {
  if (
    isBankPayment(paymentType) &&
    (isAsaprintDelivery(deliveryType) ||
      isEmailDelivery(deliveryType) ||
      isCourierDelivery(deliveryType) ||
      isPickupDelivery(deliveryType) ||
      isAssemblyDelivery(deliveryType))
  ) {
    return true;
  }

  if (
    isFreePayment(paymentType) &&
    (isAsaprintDelivery(deliveryType) ||
      isEmailDelivery(deliveryType) ||
      isCourierDelivery(deliveryType) ||
      isPickupDelivery(deliveryType) ||
      isAssemblyDelivery(deliveryType))
  ) {
    return true;
  }

  if (
    paymentType === PaymentTypes.CASH_PAYMENT_WITHOUT &&
    (isAsaprintDelivery(deliveryType) ||
      isEmailDelivery(deliveryType) ||
      isCourierDelivery(deliveryType) ||
      isPickupDelivery(deliveryType) ||
      isAssemblyDelivery(deliveryType))
  ) {
    return true;
  }

  if (
    isPreInvoicePayment(paymentType) &&
    (isAsaprintDelivery(deliveryType) ||
      isEmailDelivery(deliveryType) ||
      isCourierDelivery(deliveryType) ||
      isPickupDelivery(deliveryType) ||
      isAssemblyDelivery(deliveryType))
  ) {
    return true;
  }

  if (paymentType === PaymentTypes.CASH_ON_DELIVERY && isCourierDelivery(deliveryType)) {
    return true;
  }

  if (
    paymentType === PaymentTypes.PAYMENT_ON_SITE &&
    (isEmailDelivery(deliveryType) || isPickupDelivery(deliveryType) || isAssemblyDelivery(deliveryType))
  ) {
    return true;
  }

  return false;
}
